
import "./checkout-submit-notification-v1"
import "./checkout-apple-pay-button-v1"
import { CheckoutSubmit } from "@yggdrasil/Checkout/V2/Services/checkout-submit"
import { CF2Component } from 'javascript/lander/runtime'

export default class CheckoutSubmitButtonV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}



  mount() {
    this.storeButtonInitialHTML()

    this.getSubmitButton().addEventListener('click', (evt) => {
      evt.preventDefault()
      CheckoutSubmit.submitFromButtonClick(this.element)
    })

    const checkoutElement = this.element.closest('.elCheckout')

    Checkout.store.submitting.listen((submitting) => {
      const state = submitting.state
      switch (state) {
        case Checkout.SubmittingStates.ERROR: {
          this.setButtonInitialHTML()
          break;
        }
        case Checkout.SubmittingStates.IDLE: {
          this.setButtonInitialHTML()
          break;
        }
        case Checkout.SubmittingStates.START: {
          this.setSubmittingText()
          break;
        }
      }
    })
  }

  getSubmitButton() {
    return this.element.querySelector('[href="#submit-checkout-form"]')
  }

  storeButtonInitialHTML() {
    const buttonMainText = this.element.querySelector('.elButtonMain .elButtonMainText')
    const buttonSubText = this.element.querySelector('.elButtonSub')

    this.buttonMainTextInitialHTML = buttonMainText.innerHTML
    this.buttonSubTextInitialHTML = buttonSubText.innerHTML
  }

  setButtonInitialHTML() {
    const buttonMain = this.element.querySelector('.elButtonMain')
    const buttonSub = this.element.querySelector('.elButtonSub')
    buttonMain.querySelector('.elButtonSpinner').style.removeProperty('display');

    const buttonMainText = buttonMain.querySelector('.elButtonMainText')
    buttonMainText.innerHTML = this.buttonMainTextInitialHTML
    buttonSub.innerHTML = this.buttonSubTextInitialHTML
  }

  setSubmittingText() {
    const buttonMain = this.element.querySelector('.elButtonMain')
    const buttonSub = this.element.querySelector('.elButtonSub')

    buttonMain.querySelector('.elButtonSpinner').style.display = 'inline-block'

    const buttonMainText = buttonMain.querySelector('.elButtonMainText')
    buttonMainText.innerHTML = this.submittingText
    buttonSub.innerHTML = ''
  }



}

window["CheckoutSubmitButtonV1"] = CheckoutSubmitButtonV1

