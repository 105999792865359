
 import  { atom, computed } from "nanostores"

import "./modal-v1"
import "./checkout-saved-address-form-v1"
import "./checkbox-v1"
import { parseAddressByFields, addressToString } from '@yggdrasil/Checkout/V2/Utils/checkout-address'
import { CF2Component } from 'javascript/lander/runtime'

export default class CheckoutSavedBillingAddressV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}


  mount() {
    this.currentBillingSameAsShipping = atom(Checkout.store.billingSameAsShipping.get())
    this.modalShow = atom(false)

    this.getOpenModalButton().addEventListener('click', (evt) => {
      evt.preventDefault();
      this.modalShow.set(true)
    })
    this.getCloseButton().addEventListener('click', (evt) => {
      evt.preventDefault();
      this.modalShow.set(false)
    })
    this.getModal().onClose = () => {
      this.modalShow.set(false)
    }

    this.getCheckbox().addEventListener('change', (evt) => {
      evt.stopPropagation();
      this.currentBillingSameAsShipping.set(!!evt.target.checked)
    })

    this.getUpdateButton().addEventListener('click', (evt) => {
      evt.preventDefault();
      this.updateAddress()
    })

    this.modalShow.listen((value) => {
      if (value) {
        this.showModal()
      } else {
        this.hideModal()
        const storeBillingSameAsShipping = Checkout.store.billingSameAsShipping.get()
        if (!storeBillingSameAsShipping) {
          const currentAddress = Checkout.store.billing.get()
          const id = currentAddress?.id ?? 'new-address'
          this.getSavedAddressForm().getAddressInputByValue(id).checked = true
        }
      }
    })

    Checkout.store.billingSameAsShipping.listen((value) => {
      this.currentBillingSameAsShipping.set(value)
    })

    this.currentBillingSameAsShipping.listen((value) => {
      this.getCheckbox().checked = value
      if (value) {
        this.hideSelectAddressForm()
      } else {
        this.showSelectAddressForm()
      }
    })

    const computedSavedBillingAddress = computed([
      Checkout.store.billing,
      Checkout.store.shipping,
      Checkout.store.billingSameAsShipping,
    ], (billing, shipping, billingSameAsShipping) => {
      return billingSameAsShipping ? shipping : billing
    })

    computedSavedBillingAddress.listen((savedAddress) => {
      this.refreshDetailsElement(savedAddress)
    })

    Checkout.computed.hasPhysicalProducts.subscribe(hasPhysicalProducts => {
      if (hasPhysicalProducts) {
        this.showBillingSameAsShipping()
        if (Checkout.store.billingSameAsShipping.get()) {
          this.hideSelectAddressForm()
        } else {
          this.showSelectAddressForm()
        }
      } else {
        this.hideBillingSameAsShipping()
        this.showSelectAddressForm()
      }
    })
    this.listenToBillingApiErrors()

    Checkout.computed.isNewDigitalWalletPayment.subscribe(isNewDigitalWalletPayment => {
      if (isNewDigitalWalletPayment) {
        this.hide()
      } else {
        this.show()
      }
    })
  }

  updateAddress() {
    const savedAddrComp = this.getSavedAddressForm()
    if (Checkout.utils.hasPhysicalProducts() && this.currentBillingSameAsShipping.get()) {
      Checkout.store.billingSameAsShipping.set(true)
      const shippingData = Checkout.store.shipping.get()
      Checkout.store.billing.set(shippingData)
      this.modalShow.set(false)
    } else {
      if (savedAddrComp.isNewAddress()) {
        if (savedAddrComp.validateNewAddressForm()) {
          Checkout.store.billingSameAsShipping.set(false)
          const newAddress = savedAddrComp.getAddressFormValues()
          Checkout.store.billing.set(newAddress)
          this.modalShow.set(false)
        }
      } else {
        Checkout.store.billingSameAsShipping.set(false)
        const selectedAddressId = savedAddrComp.getSelectedAddressId()
        const newAddress = savedAddrComp.getSavedAddressById(selectedAddressId)
        if (newAddress) {
          Checkout.store.billing.set(newAddress)
        }
        this.modalShow.set(false)
      }
    }
  }

  refreshDetailsElement(address) {
    const detailsTextElement = this.element.querySelector('.elSavedDetailsText')
    const detailsIconElement = this.element.querySelector('.elSavedDetailsIcon')
    if (address && Object.keys(address).length) {
      const billingSameAsShipping = Checkout.store.billingSameAsShipping.get()
      const addressByFields = billingSameAsShipping ? address : parseAddressByFields(address, this.fields, false)
      const formattedText = addressToString(address)
      detailsTextElement.innerHTML = formattedText
      detailsTextElement.classList.remove('elError')
      detailsIconElement.classList.remove('elError')
    } else {
      detailsTextElement.innerHTML = 'Requires address configuration'
      detailsTextElement.classList.add('elError')
      detailsIconElement.classList.add('elError')
    }
  }
  getModal() {
    return this.getComponent("Modal/V1")
  }
  getOpenModalButton() {
    return this.element.querySelector('.elSavedDetailsEdit')
  }
  showModal() {
    this.getModal().element.style.display = 'flex'
  }
  hideModal() {
    this.getModal().element.style.display = 'none'
  }
  hideSelectAddressForm() {
    this.getSelectAddressForm().classList.add('elHide')
  }
  showSelectAddressForm() {
    this.getSelectAddressForm().classList.remove('elHide')
  }
  getSelectAddressForm() {
    return this.getModal().element.querySelector('.elCheckoutSelectAddressWrapper')
  }
  getUpdateButton() {
    return this.getModal().element.querySelector('.elButton')
  }
  getCheckbox() {
    return this.getBillingSameAsShippingCheckbox().querySelector('input')
  }
  getCloseButton() {
    return this.getModal().element.querySelector('.elCheckoutModalFormClose')
  }
  getSavedAddressForm() {
    return this.getComponent('CheckoutSavedAddressForm/V1')
  }
  getBillingSameAsShippingCheckbox() {
    return this.element.querySelector('.elShippingSameAsBillingContainer')
  }
  showBillingSameAsShipping() {
    return this.getBillingSameAsShippingCheckbox().classList.remove('elHide')
  }
  hideBillingSameAsShipping() {
    return this.getBillingSameAsShippingCheckbox().classList.add('elHide')
  }
  listenToBillingApiErrors() {
    Checkout.store.billingApiErrorsByField.listen((apiErrors) => {
      const errorWrapper = this.element.querySelector('.elCheckoutFormErrors')
      const savedDetailsContainer = this.element.querySelector('.elSavedDetailsContainer')
      errorWrapper.classList.add('elHide')
      savedDetailsContainer.classList.remove('errorBillingAddress')
      if (apiErrors) {
        const errors = []
        Object.entries(apiErrors).forEach(([field, error]) => {
          const {message} = error
          errors.push(message)
        })

        if (errors.length) {
          const listErrors = `<ul>${errors.map(err => `<li>${err}</li>`).join('')}</ul>`
          errorWrapper.innerHTML = listErrors
          errorWrapper.classList.remove('elHide')
          savedDetailsContainer.classList.add('errorBillingAddress')
        }
      }
    })
  }
  show() {
    this.element.classList.remove('elHide');
  }
  hide() {
    this.element.classList.add('elHide');
  }



}

window["CheckoutSavedBillingAddressV1"] = CheckoutSavedBillingAddressV1

