
import "./input-v1"
import { resetInputErrors, addError } from '@yggdrasil/Checkout/V2/Utils/checkout-input-validator'
import { CF2Component } from 'javascript/lander/runtime'

export default class CheckoutContactFormV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}


  mount() {
    Checkout.store.contact.listen((contactData) => {
      this.fields.forEach((prop) => {
        const input = this.getInputFromName(prop)
        const inputValue = input.value
        const newValue = contactData[prop]
        if (newValue) {
          if (prop === 'phone_number') {
            input.iti.setNumber(newValue)
          } else {
            input.value = newValue
          }
          $(input).parents('.elFormItemWrapper').addClass('hasValue')
        } else {
          input.value = ''
          $(input).parents('.elFormItemWrapper').removeClass('hasValue')
        }
      })
    })

    this.fields.forEach((prop) => {
      const input = this.getInputFromName(prop)
      input.addEventListener('blur', () => {
        let value
        if (prop === 'phone_number') {
          value = input.iti.getNumber()
        } else {
          value = input.value
        }
        Checkout.store.contact.setKey(prop, value)
        window.cfGarlicUtils.store(prop, value)
        
        // We need to re-validate form because input may not be changed,
        // and we removed the errors on focus.
        if (!this.disableInputValidationOnBlur) {
          this.checkForm(Checkout.computed.contactErrors.get())
        } else {
          if (Checkout.store.showAllErrors.contact.get()) this.checkForm(Checkout.computed.contactErrors.get())
        }
      })
    })

    Checkout.computed.contactErrors.subscribe((contactErrors) => {
      if (!this.disableInputValidationOnBlur) {
        this.checkForm(contactErrors)
      } else {
        if (Checkout.store.showAllErrors.contact.get()) this.checkForm(contactErrors)
      }
    })

    Checkout.store.phoneNumberInitialized.listen((phoneNumberInitialized) => {
      if (phoneNumberInitialized) {
        this.initializePhoneNumber()
      }
    })

    Checkout.computed.hideContactInformationForm.subscribe((hideContactInformationForm) => {
      if (hideContactInformationForm) {
        this.element.classList.add('elHide')
      } else {
        this.element.classList.remove('elHide')
      }
    })
  }

  checkForm(contactErrors) {
    const errorWrapper = this.element.querySelector('.elCheckoutFormErrors')
    errorWrapper.classList.add('elHide')
    this.fields.forEach((prop) => {
      const input = this.getInputFromName(prop)
      resetInputErrors(input)
    })

    if (Checkout.utils.hasErrors(contactErrors)) {
      const fieldErrors = contactErrors.fields
      if (fieldErrors) {
        const errors = []
        Object.entries(fieldErrors).forEach(([field, error]) => {
          const {message} = error
          errors.push(message)
          const input = this.getInputFromName(field)
          addError(input)
        })

        if (errors.length) {
          const listErrors = `<ul>${errors.map(err => `<li>${err}</li>`).join('')}</ul>`
          errorWrapper.innerHTML = listErrors
          errorWrapper.classList.remove('elHide')
        } else {
          errorWrapper.classList.add('elHide')
        }
      }
    }
  }

  getInputFromName(name) {
     return this.element.querySelector(`[name='${name}']`)
  }

  // TODO: The intlTelInput setup should be handled by the Input/V1 blueprint
  initializePhoneNumber() {
    const phoneInput = this.getInputFromName('phone_number')
    if (!phoneInput) return
    const initialCountry = Checkout.store.shipping.get().country
    const iti = window.intlTelInput(phoneInput, {
      autoPlaceholder: "aggressive",
      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.16/build/js/utils.js",
      preferredCountries: ["us", "ca", "gb", "ie", "ai", "nz"],
      initialCountry: initialCountry.toLowerCase()
    });

    phoneInput.iti = iti;
    phoneInput.addEventListener("countrychange", () => {
      Checkout.store.contact.setKey('phone_number', phoneInput.iti.getNumber())
    });
  }



}

window["CheckoutContactFormV1"] = CheckoutContactFormV1

